import React, { useState, useEffect } from "react";
//import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
//import { Testimonials } from "./components/testimonials";
import { Customers } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import CookieConsent from "react-cookie-consent";
import "./App.css";
import { Link } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <CookieConsent
          location="bottom"
          buttonText="Aceitar e Fechar"
          cookieName="zestotecnologia"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#F05B22", color: "#fff", fontSize: "15px", fontWeight: "bold" }}
          expires={150}
      >
        <span style={{ fontSize: "15px" }}>
          Este site usa cookies para melhorar sua experiência. 
          Ao clicar em "Aceitar e Fechar" você concorda com o uso dos cookies, 
          termos e políticas do site.{" "}
        </span>
        <Link to="/termos" target="_blank"><strong>Leia mais</strong></Link>
        
          
      </CookieConsent>
      
      <Header data={landingPageData.Header} />
       
      <About data={landingPageData.About} />     
      <Services data={landingPageData.Services} />
      <Features data={landingPageData.Features} />          
      <Customers data={landingPageData.Customers} />
      <Gallery data={landingPageData.Gallery} />  
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;
