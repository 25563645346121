import React from "react";

export const Customers = (props) => {
  return (
    <div id="customers" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Clientes</h2>
          <p>
           Veja quem confia no nosso trabalho.
          </p>
        </div>
        <div id="row">
          <img className="logo-customers" src="./img/clientes/logo-prio.png" alt="prio" />
          <img className="logo-customers" src="./img/clientes/logo-shipay.png" alt="shipay" />
          <img className="logo-customers" src="./img/clientes/logo-eisengardai.png" alt="eisengard" />
          <img className="logo-customers" src="./img/clientes/logo-fiotec.png" alt="fiotec" />
          <img className="logo-customers" src="./img/clientes/logo-wayne.png" alt="wayne" />
          
          
        </div>
      </div>
    </div>
  );
};
