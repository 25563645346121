import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from "./Home";
import Termos from "./Termos";


const App = () => {
   return (
    <BrowserRouter>
    <Routes>
        <Route path="/"  element = { <Home/> }/> 
        <Route path="/termos"  element = { <Termos/> }/>
        <Route index element={<Home />} />
    </Routes>
                        
    </BrowserRouter>
  );
};

export default App;
