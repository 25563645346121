import { Image } from "./image";
import React, {useState} from "react";
import Modal from "react-modal";


Modal.setAppElement('#root');

export const Gallery = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [projectData, setProjectData] = useState("");

  

  function abrirModal (data){
    setIsOpen(true);
    setProjectData(data);
    
  }
  function fecharModal() {
    setIsOpen(false);
    setProjectData({name:"", desc:"" });
  }
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Projetos</h2>
          <p>
            Conheça um pouco do nosso trabalho.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">           
                  <div
                    key=""
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => abrirModal({
                      name:"CCR Logs - PRIO",
                      desc:"Aplicação de registro de eventos (logs) para rastrear as operações diárias de uma plataforma de petróleo, com integração ao Plant Information, servidor de e-mail e Windows AD.",
                      img: "img/projetos/prio02.jpg"
                    })}
                  >
                    <Image
                      title="CCR Logs - PRIO"                    
                      smallImage="img/projetos/prio01.jpg"
                      
                    />                                        
                  </div>     
                  <Modal 
                        style={{                        
                          content: {
                            position: 'absolute',
                            color: '#000',
                            marginTop: '60px',                           
                            paddingTop: '5vh',
                            marginLeft: '10vw',
                            width: '70vw'
                          }
                        }}
                        overlayClassName="modal-overlay"
                        isOpen={modalIsOpen}
                        onRequestClose={fecharModal}
                        contentLabel=""
                        onClick={fecharModal}
                    >
                        
                        <div className="project-modal" onClick={fecharModal}>
                          <div><h3>{projectData.name}</h3></div>
                          <hr></hr> 
                          <div className="project-modal-img">
                               <img className="modal-image" alt="prio01" src={projectData.img} />
                          </div>
                          <hr></hr>                          
                          <div className="project-modal-desc">
                              {projectData.desc}
                          </div>                       
                         
                        </div>
                  </Modal>    
                  <div
                    key=""
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => abrirModal({
                      name:"Painel Shipay",
                      desc:"Aplicação web desenvolvida para empresa de pagamentos digitais com funcionalidades como: cadastro de carteira, logs de transações e até um gerador de landing page onde o cliente pode criar uma página customizada para sua campanha publicitária.",
                      img: "img/projetos/shipay02.jpg"
                    })}
                  >
                    <Image
                      title="Painel Shipay"                    
                      smallImage="img/projetos/shipay01.jpg"
                      
                    />                                        
                  </div> 
                  <div
                    key=""
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => abrirModal({
                      name:"App p/ normativa do INMETRO - Wayne",
                      desc:"Aplicativo desenvolvido para sistemas de abastecimento Wayne que estabelece uma comunicação Bluetooh Low Energy(BLE) UART com um dispositivo conectado ao CAN da bomba de gasolina para recuperar informações sobre a integridade do software e o estado da bomba.",
                      img: "img/projetos/wayne02.jpg"
                    })}
                  >
                    <Image
                      title="Normativa INMETRO - Wayne"                    
                      smallImage="img/projetos/wayne01.jpg"
                      
                    />                                        
                  </div>   
                  <div
                    key=""
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => abrirModal({
                      name:"Tela Balcão - Shipay",
                      desc:"Display integrado com PDV para gerar QR codes dinâmicos de uma ordem de pagamento. Criamos um pequeno dispositivo com microcontrolador ESP-32 para se comunicar via wi-fi com um tópico MQTT. Desenvolvemos desde a caixa impressa em 3D até o firmware do dispositivo.",
                      img: "img/projetos/shipay04.jpg"
                    })}
                  >
                    <Image
                      title="Tela Balcão - Shipay"                    
                      smallImage="img/projetos/shipay03.jpg"
                      
                    />                                        
                  </div>          
                          
          </div>
          
         
        </div>
        
      </div>
    </div>
  );
};
