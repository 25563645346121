import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faLaptop, faGears, faRobot, faObjectGroup,faMicrochip } from '@fortawesome/free-solid-svg-icons'



export const Services = (props) => {
 
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nossos serviços</h2>
          <p>
           Veja o que podemos construir juntos.
          </p>
        </div>
        <div className="row">                 
                <div  className="col-md-4">                                      
                  <div className="service-desc">
                    <div className="fa">
                      <FontAwesomeIcon icon={faMobile} size="4x"/>
                    </div>
                    <h3>Mobile</h3>
                    <p>Desenvolvimento de aplicativos iOS e Android</p>
                  </div>
                </div>       
                <div className="col-md-4">                                      
                  <div className="service-desc">
                    <div className="fa">
                      <FontAwesomeIcon icon={faLaptop} size="4x"/>
                    </div>
                    <h3>Web</h3>
                    <p>Desenvolvimento de sistemas, portais, sites corporativos e e-commerce.</p>
                  </div>
                </div>                
                <div  className="col-md-4">
                                      
                  <div className="service-desc">
                    <div className="fa">
                      <FontAwesomeIcon icon={faGears} size="4x"/>
                    </div>
                    <h3>Integrações</h3>
                    <p>API e integrações de sistemas</p>
                  </div>
                </div>        
        </div>
        <div className="row">   
                <div  className="col-md-4">                                      
                  <div className="service-desc">
                    <div className="fa">
                      <FontAwesomeIcon icon={faRobot} size="4x"/>
                    </div>
                    <h3>Automações e IA</h3>
                    <p>Automações de processos (RPA) e Inteligência artificial</p>
                  </div>
                </div>        
                <div className="col-md-4">                                      
                  <div className="service-desc">
                    <div className="fa">
                      <FontAwesomeIcon icon={faObjectGroup} size="4x"/>
                    </div>
                    <h3>UX Design</h3>
                    <p>Ideação e prototipação de sistemas</p>
                  </div>
                </div>        
                <div  className="col-md-4">                                      
                  <div className="service-desc">
                    <div className="fa">
                      <FontAwesomeIcon icon={faMicrochip} size="4x"/>
                    </div>
                    <h3>Internet da Coisas (IoT)</h3>
                    <p>Criação de dispositivos, sensores, automações e protótipos.</p>
                  </div>
                </div>        
        </div>
      </div>
    </div>
  );
};
