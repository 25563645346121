import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ReCAPTCHA from "react-google-recaptcha";
import { faCoffee } from '@fortawesome/free-solid-svg-icons'



const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const captchaRef = useRef(null)
  const [captcha, setCaptcha] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    if(captcha != ""){
      emailjs
      .sendForm("service_snsqddd", "template_8o0crtb", e.target, "bxqf24Zg1ljE470BA")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          setOpen(o => !o);
          const token = captchaRef.current.getValue();
          captchaRef.current.reset();
          setCaptcha("");
        },
        (error) => {
          console.log(error.text);
        }
      );

    } else {     
      alert("Favor clicar em 'Não sou um robô'!")

    }
    
  };
  return (
    <div>
         
      <div id="contact">
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
            <div className="popup-alert" onClick={closeModal}>
              <a className="close" onClick={closeModal}>
                &times;
              </a>
              <strong>Obrigado! </strong>Recebemos a sua mensagem!
            </div>
          </Popup>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Fale Conosco</h2>
                <p>
                 Vamos marcar um café e conversar sobre o seu projeto? <FontAwesomeIcon icon={faCoffee} />
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                   
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nome"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="E-mail"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensagem"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY} 
                        ref={captchaRef}
                        onChange={setCaptcha}
                    />
                <div id="success"></div>
                
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar mensagem
                </button>
                
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>+ Informações</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Onde estamos
                </span>
                Rio de Janeiro, RJ - Brasil
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefone
                </span>{" "}
               +55 21 2018-4516
              </p>
            </div>
            <div className="contact-item">
              <p>
                
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/company/zestotech" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/zestotecnologia/" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Zesto Tecnologia da Informação LTDA            
          </p>
        </div>
      </div>
    </div>
  );
};
