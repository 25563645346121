import React from "react";

export const Features = (props) => {
  
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Tecnologias</h2>
          <p>
           Nós trabalhamos com as melhores ferramentas e tecnologias disponíveis no mercado.
          </p>
        </div>
        <div className="row">
          <img className="logo-tech" src="./img/features/react.png" alt="react" />
          <img className="logo-tech" src="./img/features/tailwind.png" alt="tailwindcss"/>           
          <img className="logo-tech" src="./img/features/nextjs.png" alt="nextjs"/>           
          <img className="logo-tech" src="./img/features/python.png" alt="python"/>
          <img className="logo-tech-square" src="./img/features/nodejs.png" alt="nodejs"/>
          <img className="logo-tech-square" src="./img/features/react-native.png" alt="reactnative"/>

        </div>
        <div className="row">
          <img className="logo-tech" src="./img/features/firebase.png" alt="firebase"/>          
          <img className="logo-tech-square" src="./img/features/figma.png" alt="figma"/>
          <img className="logo-tech-square" src="./img/features/flutter.png" alt="flutter"/>
          <img className="logo-tech" src="./img/features/postgres.png" alt="postgres"/>
          <img className="logo-tech" src="./img/features/mongodb.png" alt="mongodb"/>
          <br></br>
          

           
        </div>
      </div>
    </div>
  );
};
