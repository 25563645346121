import React, { useState, useEffect } from "react";
//import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
//import { Testimonials } from "./components/testimonials";
import { Customers } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import CookieConsent from "react-cookie-consent";
import "./App.css";


const Termos = () => {
 
  return (
    <div className="termos">
      <br/><br/>
     
      <img src="../img/logo-zesto-white.png" height="36vh" alt="zesto"  />
      <br/><br/>

      <h2>
        Termos de uso do site                                 
      </h2>
      
      <h3>Política de Privacidade</h3><p>
      Em nosso site Zesto, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos usuários/clientes. Por isso, esta presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam o site.
      <br/><br/>
      Ao utilizar os serviços, você entende que será coletado e usado suas informações pessoais nas formas descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.
      <br/><br/>

      <h3>1. Quais dados serão coletados sobre você e para qual finalidade?</h3>
      O site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços e aprimorar a experiência de uso.
      <br/><br/>
      1.1. Dados pessoais fornecidos pelo titular
      Nome, e-mail, número de contato, e informações que possibilitam que a apresentação do serviço, seja iniciada de forma personalizada, de acordo com os objetivos do potencial cliente.
      <br/><br/>
      1.2. Dados pessoais coletados automaticamente
      Utilizamos plataformas de dados analíticos que tem como objetivo coletar informações dos usuários ou visitantes de um site. Essas informações dividem-se em diversas métricas que a plataforma considera, como: idade dos usuários, gênero, localização geográfica, comportamentos, ações realizadas no site, entre outros.
      <br/><br/><br/>

      <h3>2. Como coletamos os seus dados?</h3>
      Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma:
      <br/><br/>
      <li>
        Através do preenchimento do formulário que se encontra no site zesto.com.br;
      </li>
      <li>
      Através do acesso ao site, com o uso de cookies e ferramentas estatísticas.
      </li><br/>
      2.1. Consentimento<br/>
      É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a Zesto a tratar seus dados.
      <br/><br/>
      Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.
      <br/><br/>
      O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da Zesto para com seus usuários/clientes, seguindo as regulações legislativas pertinentes.
      <br/><br/>
      Ao utilizar os serviços da Zesto e fornecer seus dados pessoais, você está ciente e consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.
      <br/><br/>
      A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.
      <br/><br/>
      É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a impossibilidade da performance adequada de alguma funcionalidade do site que dependa da operação. Tais consequências serão informadas previamente.
      <br/><br/>

      <h3>3. Quais são os seus direitos?</h3>
      A Zesto assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados.
      <br/><br/>

      <h3>4. Como você pode exercer seus direitos de titular?</h3>
      Para exercer seus direitos de titular, você deve entrar em contato com a Zesto através do meio disponível:
      <br/><br/>
      E-mail: contato@zesto.com.br 
      <br/><br/>
      De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você será informado previamente.
      <br/><br/>

      <h3>5. Como e por quanto tempo seus dados serão armazenados?</h3>
      Seus dados pessoais coletados pela Zesto serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.

      De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a Zesto perdurar. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:
      <br/><br/>
      <strong>I</strong> – cumprimento de obrigação legal ou regulatória pelo controlador;
      <br/><br/>
      <strong>II</strong> – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
      <br/><br/>
      <strong>III</strong> – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou
      <br/><br/>
      <strong>IV</strong> – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
      <br/><br/>
      Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados.
      <br/>
      O armazenamento de dados coletados pela Zesto reflete o nosso compromisso com a segurança e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
      <br/><br/>

      <h3>6. O que fazemos para manter seus dados seguros?</h3>
      Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.
      <br/><br/>
      Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
      <br/><br/>
      Entre as medidas que adotamos, destacamos as seguintes:
      <br/><br/>
      Apenas pessoas autorizadas têm acesso a seus dados pessoais. O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade. Seus dados pessoais são armazenados em ambiente seguro e idôneo.
      <br/><br/>
      A Zesto se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente.
      <br/><br/>
      Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.
      <br/><br/>

      <h3>7. Com quem seus dados podem ser compartilhados?</h3>
      Tendo em vista a preservação de sua privacidade, a Zesto não compartilhará seus dados pessoais com nenhum terceiro não autorizado com antecedência.
      <br/><br/>

      <h3>8. Cookies ou dados de navegação</h3>
      A Zesto faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para aprimorar a experiência de uso.
      <br/><br/>
      Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
      <br/><br/>
      A Zesto utiliza os seguintes Cookies: idade dos usuários, gênero, localização geográfica, comportamentos, ações realizadas no site.
      <br/><br/>
      Você pode, a qualquer tempo e sem nenhum custo, descartar os Cookies. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
      <br/><br/>
      Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
      <br/><br/>

      <h3>9. Alteração desta Política de Privacidade</h3>
      A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 23/09/2021.
      <br/><br/>
      Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise com frequência.
      <br/><br/>
      Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
      <br/><br/>
      Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente.
      <br/><br/>

      <h3>10. Responsabilidade</h3>
      **A Zesto prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
      <br/><br/>
      Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento.
      <br/><br/>
      Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.
      <br/><br/>
      **Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados realizado pela Zesto, comprometemo-nos a segui-las.
      <br/><br/>
      10.1 Isenção de responsabilidade<br/>
      Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a Zesto não se responsabiliza por:
      <br/><br/>
      I – Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.
      <br/><br/>
      Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.
      <br/><br/>
      II – Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada da Zesto.
      <br/><br/>
      Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.
      <br/><br/>
      III – Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos serviços da Zesto; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário/cliente.
      <br/><br/><br/>

      <h3>11. Encarregado de Proteção de Dados</h3>
      A Zesto disponibiliza o seguinte meio para que você possa entrar em contato conosco para exercer seus direitos de titular: 
      <br/><br/>
      E-mail: contato@zesto.com.br 
      <br/><br/>
      Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato.
      <br/><br/>


      ATENÇÃO! SE VOCÊ SE INSCREVER NA NOSSA BASE DE DADOS EM UM SITE DE TERCEIROS, OU POR MEIO DE UM APLICATIVO DE TERCEIROS, QUALQUER INFORMAÇÃO INSERIDA NAQUELE SITE OU APLICATIVO (E NÃO DIRETAMENTE NO NOSSO SITE) SERÁ COMPARTILHADA COM O PROPRIETÁRIO DESSE SITE OU APLICATIVO, E SUAS INFORMAÇÕES PODEM ESTAR SUJEITAS AS POLÍTICAS DE PRIVACIDADE DELES.</p>
      <br/><br/>                
          

    
      
    </div>
  );
};

export default Termos;
